<template>
  <div>
    <svg
      viewBox="0 0 11 9"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height, color]"
    >
      <path
        d="m0.48877 0.92969h10v1.5306l-5 2.8443-5-2.8443v-1.5306z"
        fill="#1B2CC1"
      />
      <path
        d="m10.489 8.5526v-5.4354l-5 2.844-5-2.844v5.4354h10z"
        fill="#1B2CC1"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-7'
    },
    height: {
      type: String,
      default: 'h-7'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
