import firebase from 'firebase/app'

// Add the Firebase products
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/analytics'

var firebaseConfig = {
  apiKey: 'AIzaSyB4r464yte_to-Jb-B5ZaatoU7cUMORLdc',
  authDomain: 'alpha-prime-90aad.firebaseapp.com',
  projectId: 'alpha-prime-90aad',
  storageBucket: 'alpha-prime-90aad.appspot.com',
  messagingSenderId: '22702952058',
  appId: '1:22702952058:web:14d32eeac2a4b46bdcf5c3',
  measurementId: 'G-V42HPXZET8'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

firebase
  .firestore()
  .enablePersistence()
  .catch(err => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })

const db = firebase.firestore()
const auth = firebase.auth()
const fStore = firebase.storage()
const currentUser = auth.currentUser
const analytics = firebase.analytics()

export default {
  db,
  auth,
  currentUser,
  fStore,
  analytics
}
