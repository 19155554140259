import Vue from 'vue'
import Vuex from 'vuex'
import fb from '../services/firebase'
import Swal from 'sweetalert2'
import router from '../router'
import * as middleware from './modules/middleware'
import * as users_list from './modules/users_list'
import * as hospital_list from './modules/hospital_list'
import * as card from './modules/card'
import * as pets from './modules/pets'
import * as farms from './modules/farms'
import * as clients from './modules/clients'
import * as reports from './modules/reports/reports'
import * as reports_history from './modules/reports/reports_history'
import * as medical_referred from './modules/medical/referred'
import * as medical_chief_compliant from './modules/medical/chief_compliant'
import * as medical_medical_history from './modules/medical/medical_history'
import * as medical_physical_examination from './modules/medical/physical_exam'
import * as medical_diagnosis from './modules/medical/diagnosis'
import * as medical_discharge from './modules/medical/discharge'
import * as medical_submitted_by from './modules/medical/submitted_by'
import * as others_animal_bite from './modules/others/animal_bite'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_name: null,
    user: {},
    loading: false,
    isUserLoggedIn: false,
    new_app_version: false,
    current_version: null
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_APP_VERSION(state, payload) {
      state.new_app_version = payload
    },
    SET_CURRENT_VERSION(state, payload) {
      state.current_version = payload
    },
    SET_USER_LOGGED(state, payload) {
      state.isUserLoggedIn = payload
    }
  },

  actions: {
    register({ commit, dispatch }, userDetails) {
      commit('SET_LOADING', true)
      fb.auth
        .createUserWithEmailAndPassword(userDetails.email, userDetails.password)
        .then(userCredential => {
          var user = userCredential.user
          userDetails.uid = user.uid
          delete userDetails.password
          dispatch('saveUser', userDetails)
          commit('SET_LOADING', false)
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: error.code,
            text: error.message
          })
        })
    },

    logIn({ commit, dispatch }, userDetails) {
      commit('SET_LOADING', true)
      fb.auth
        .signInWithEmailAndPassword(userDetails.email, userDetails.password)
        .then(userCredential => {
          var user = userCredential.user
          userDetails.uid = user.uid
          dispatch('setUser', user)
          router.push({
            name: 'Dashboard'
          })
          commit('SET_LOADING', false)
        })
        .catch(error => {
          var errorCode = error.code
          var errorMsg = error.message
          Swal.fire(errorCode, errorMsg, 'error')
        })
    },

    saveUser({ commit, dispatch }, userDetails) {
      commit('SET_LOADING', true)
      var uid = userDetails.uid
      delete userDetails.uid
      fb.db
        .collection('users')
        .doc(uid)
        .set(userDetails)
        .then(() => {
          dispatch('confirmUser')
          commit('SET_LOADING', false)
        })
        .then(() => {
          if (router.currentRoute.name != 'Dashboard') {
            router.push({
              name: 'Dashboard'
            })
          }
        })
    },

    confirmUser({ commit, dispatch }) {
      fb.auth.onAuthStateChanged(user => {
        if (user) {
          dispatch('setUser', user)
          commit('SET_USER_LOGGED', true)
        } else {
          commit('SET_USER_LOGGED', false)
        }
      })
    },

    setUser({ commit }, user) {
      var userInfo = {}
      fb.db
        .collection('users')
        .doc(user.uid)
        .onSnapshot(doc => {
          if (doc.exists) {
            userInfo = doc.data()
            userInfo.id = doc.id
          }
          commit('SET_USER', userInfo)
        })
    },

    getCurrentUser({ getters }) {
      const user = fb.auth.currentUser
      if (user !== null) {
        getters.getCurrentUserName = user
      }
    },

    signOut({ commit }) {
      fb.auth.signOut().then(() => {
        commit('SET_USER', '')
        Swal.fire({
          icon: 'success',
          title: 'Signed Out',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          router.push({
            name: 'Welcome'
          })
        })
      })
    },

    appVersion({ commit }) {
      fb.db.collection('app-version').onSnapshot(docs => {
        var version = docs.docs[0].data().version
        var length = version.length
        var currentVersion = version[length - 1]
        commit('SET_CURRENT_VERSION', currentVersion)
        if (localStorage.getItem('app_version') != currentVersion) {
          commit('SET_APP_VERSION', true)
        }
      })
    },

    addAppVersionToLocalStorage({ getters }) {
      var currentVersion = getters.getCurrentVersion
      localStorage.setItem('app_version', currentVersion)
      indexedDB.deleteDatabase('firebaseLocalStorageDb')
      window.location.reload(true)
    }
  },

  getters: {
    getCurrentUserName: state => {
      return state.user_name
    },
    getCurrentVersion: state => {
      return state.current_version
    }
  },

  modules: {
    middleware,
    hospital_list,
    users_list,
    card,
    pets,
    farms,
    clients,
    reports,
    reports_history,
    medical_referred,
    medical_chief_compliant,
    medical_medical_history,
    medical_physical_examination,
    medical_diagnosis,
    medical_discharge,
    medical_submitted_by,
    others_animal_bite
  }
})
