<template>
  <main>
    <home-nav></home-nav>
    <home-hero></home-hero>
    <how-it-work-section></how-it-work-section>
    <home-feature-section></home-feature-section>
    <!-- <home-carousel></home-carousel> -->
    <contact-us></contact-us>
    <in-the-news></in-the-news>
    <home-footer></home-footer>
  </main>
</template>

<script>
import ContactUs from '../components/Home/ContactUs.vue'
// import HomeCarousel from '../components/Home/HomeCarousel.vue'
// @ is an alias to /src
import HomeFeatureSection from '../components/Home/HomeFeatureSection.vue'
import HomeFooter from '../components/Home/HomeFooter.vue'
import HomeHero from '../components/Home/HomeHero.vue'
import HomeNav from '../components/Home/HomeNav.vue'
import HowItWorkSection from '../components/Home/HowItWorkSection.vue'
import InTheNews from '../components/Home/InTheNews.vue'

export default {
  name: 'Home',
  components: {
    HomeNav,
    HomeHero,
    HowItWorkSection,
    HomeFeatureSection,
    // HomeCarousel,
    ContactUs,
    InTheNews,
    HomeFooter
  }
}
</script>
