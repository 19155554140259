<template>
  <div>
    <svg
      id="wave"
      style="transform: rotate(0deg); transition: 0.3s"
      viewBox="0 0 1440 170"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-5" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(251, 251, 251, 1)" offset="0%"></stop>
          <stop stop-color="rgba(251, 251, 251, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style="transform: translate(0, 0px); opacity: 1"
        fill="url(#sw-gradient-5)"
        d="M0,17L40,31.2C80,45,160,74,240,85C320,96,400,91,480,87.8C560,85,640,85,720,70.8C800,57,880,28,960,14.2C1040,0,1120,0,1200,14.2C1280,28,1360,57,1440,62.3C1520,68,1600,51,1680,39.7C1760,28,1840,23,1920,17C2000,11,2080,6,2160,5.7C2240,6,2320,11,2400,25.5C2480,40,2560,62,2640,68C2720,74,2800,62,2880,73.7C2960,85,3040,119,3120,130.3C3200,142,3280,130,3360,127.5C3440,125,3520,130,3600,119C3680,108,3760,79,3840,68C3920,57,4000,62,4080,68C4160,74,4240,79,4320,70.8C4400,62,4480,40,4560,25.5C4640,11,4720,6,4800,14.2C4880,23,4960,45,5040,45.3C5120,45,5200,23,5280,34C5360,45,5440,91,5520,116.2C5600,142,5680,147,5720,150.2L5760,153L5760,170L5720,170C5680,170,5600,170,5520,170C5440,170,5360,170,5280,170C5200,170,5120,170,5040,170C4960,170,4880,170,4800,170C4720,170,4640,170,4560,170C4480,170,4400,170,4320,170C4240,170,4160,170,4080,170C4000,170,3920,170,3840,170C3760,170,3680,170,3600,170C3520,170,3440,170,3360,170C3280,170,3200,170,3120,170C3040,170,2960,170,2880,170C2800,170,2720,170,2640,170C2560,170,2480,170,2400,170C2320,170,2240,170,2160,170C2080,170,2000,170,1920,170C1840,170,1760,170,1680,170C1600,170,1520,170,1440,170C1360,170,1280,170,1200,170C1120,170,1040,170,960,170C880,170,800,170,720,170C640,170,560,170,480,170C400,170,320,170,240,170C160,170,80,170,40,170L0,170Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
