<template>
  <div>
    <svg
      fill="none"
      viewBox="0 0 16 13"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height]"
    >
      <path
        d="m15.004 1.7944c-0.3205 0.14213-0.6533 0.25426-0.9947 0.33544 0.3762-0.35749 0.6591-0.80465 0.8186-1.3037 0.0456-0.14248-0.1092-0.2657-0.2378-0.1894-0.475 0.28173-0.9866 0.49308-1.5207 0.62825-0.031 0.00785-0.063 0.01182-0.0951 0.01182-0.0972 0-0.1918-0.03612-0.2662-0.10168-0.5683-0.50083-1.2991-0.77665-2.0579-0.77665-0.3283 0-0.65985 0.051176-0.98534 0.15212-1.0085 0.31279-1.7866 1.1447-2.0307 2.171-0.09159 0.38497-0.11639 0.77029-0.07376 1.1452 0.00489 0.04309-0.01548 0.07316-0.02803 0.08726-0.02206 0.02473-0.05332 0.0389-0.08579 0.0389-0.00362 0-0.00735-1.7e-4 -0.01104-5.2e-4 -2.2075-0.2051-4.1982-1.2686-5.6052-2.9946-0.07175-0.088038-0.20935-0.07724-0.26654 0.020853-0.27554 0.47273-0.42116 1.0134-0.42116 1.5636 0 0.84317 0.33875 1.6378 0.92716 2.217-0.24745-0.05866-0.48694-0.15106-0.7094-0.27462-0.10763-0.0598-0.24006 0.01705-0.24157 0.14016-0.01541 1.2526 0.71795 2.3671 1.8165 2.869-0.02212 5.2e-4 -0.04425 7.7e-4 -0.0664 7.7e-4 -0.17414 0-0.35047-0.01681-0.52401-0.04998-0.1212-0.02314-0.22303 0.09184-0.18539 0.20935 0.35658 1.1132 1.3151 1.9338 2.4622 2.1201-0.95199 0.63902-2.0606 0.97612-3.2144 0.97612l-0.35971-2e-4c-0.11083 0-0.20457 0.0723-0.23281 0.1796-0.027821 0.1057 0.023426 0.2173 0.11794 0.2723 1.3002 0.7566 2.7833 1.1565 4.2895 1.1565 1.3184 0 2.5518-0.2616 3.6657-0.7776 1.0212-0.473 1.9236-1.1492 2.6821-2.0098 0.7066-0.80166 1.2592-1.7271 1.6423-2.7506 0.3652-0.97559 0.5582-2.0166 0.5582-3.0104v-0.04735c0-0.15957 0.0721-0.30973 0.1981-0.41194 0.4778-0.38795 0.8939-0.84464 1.2366-1.3574 0.0905-0.13538-0.0522-0.30505-0.2011-0.239z"
        fill="#E1E2E4"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-5'
    },
    height: {
      type: String,
      default: 'h-5'
    }
  }
}
</script>

<style></style>
