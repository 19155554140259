<template>
  <div>
    <svg
      id="wave"
      style="transform: rotate(0deg); transition: 0.3s"
      viewBox="0 0 1440 180"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(27, 44, 193, 1)" offset="0%"></stop>
          <stop stop-color="rgba(27, 44, 193, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style="transform: translate(0, 0px); opacity: 1"
        fill="url(#sw-gradient-1)"
        d="M0,0L48,8C96,16,192,32,288,56C384,80,480,112,576,136C672,160,768,176,864,160C960,144,1056,96,1152,76C1248,56,1344,64,1440,64C1536,64,1632,56,1728,72C1824,88,1920,128,2016,128C2112,128,2208,88,2304,84C2400,80,2496,112,2592,128C2688,144,2784,144,2880,144C2976,144,3072,144,3168,152C3264,160,3360,176,3456,188C3552,200,3648,208,3744,188C3840,168,3936,120,4032,112C4128,104,4224,136,4320,128C4416,120,4512,72,4608,80C4704,88,4800,152,4896,184C4992,216,5088,216,5184,208C5280,200,5376,184,5472,152C5568,120,5664,72,5760,60C5856,48,5952,72,6048,96C6144,120,6240,144,6336,144C6432,144,6528,120,6624,104C6720,88,6816,80,6864,76L6912,72L6912,240L6864,240C6816,240,6720,240,6624,240C6528,240,6432,240,6336,240C6240,240,6144,240,6048,240C5952,240,5856,240,5760,240C5664,240,5568,240,5472,240C5376,240,5280,240,5184,240C5088,240,4992,240,4896,240C4800,240,4704,240,4608,240C4512,240,4416,240,4320,240C4224,240,4128,240,4032,240C3936,240,3840,240,3744,240C3648,240,3552,240,3456,240C3360,240,3264,240,3168,240C3072,240,2976,240,2880,240C2784,240,2688,240,2592,240C2496,240,2400,240,2304,240C2208,240,2112,240,2016,240C1920,240,1824,240,1728,240C1632,240,1536,240,1440,240C1344,240,1248,240,1152,240C1056,240,960,240,864,240C768,240,672,240,576,240C480,240,384,240,288,240C192,240,96,240,48,240L0,240Z"
      ></path>
    </svg>
  </div>
</template> 

<script>
export default {}
</script>

<style></style>

