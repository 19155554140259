<template>
  <section
    class="bg-background_color w-full h-full bg-opacity-50 pt-24 xl:pt-10 pb-12"
  >
    <section class="relative">
      <div class="absolute hidden xl:block right-0">
        <background-line></background-line>
      </div>
      <h1 class="text-center text-2xl sm:text-4xl">How Alpha Prime works</h1>
      <section class="sm:w-4/5 xl:w-3/5 w-10/12 mx-auto my-16 sm:px-24 md:px-0">
        <section
          class="
            grid grid-cols-1
            md:grid-cols-2 md:gap-x-16
            lg:gap-x-24
            gap-y-24
          "
        >
          <div class="bg-white flex flex-col rounded-3xl shadow-xl">
            <div class="flex justify-end">
              <img src="/img/Group 39.png" alt="" />
            </div>
            <div class="md:pl-8 lg:pl-16 px-8 pb-16">
              <p class="text-2xl font-medium text-center sm:text-justify">
                Create an account via the mobile app or website
              </p>
            </div>
          </div>
          <div class="bg-white flex flex-col rounded-3xl shadow-xl">
            <div class="flex justify-end">
              <img src="/img/Group 40.png" alt="" />
            </div>
            <div class="md:pl-8 lg:pl-16 px-8 pt-4 pb-16">
              <p class="text-2xl font-medium text-center sm:text-justify">
                Invite your staff or friends to join
              </p>
            </div>
          </div>
          <div class="bg-white flex flex-col rounded-3xl shadow-xl">
            <div class="flex justify-end">
              <img src="/img/Group 41.png" alt="" />
            </div>
            <div class="md:pl-8 lg:pl-16 px-8 pt-4 pb-12">
              <p class="text-2xl font-medium text-center sm:text-justify">
                Assign tasks - create animal/pet cards, submit laboratory report
                or clinical cases
              </p>
            </div>
          </div>
          <div class="bg-white flex flex-col rounded-3xl shadow-xl">
            <div class="flex justify-end">
              <img src="/img/Group 42.png" alt="" />
            </div>
            <div class="md:pl-8 lg:pl-16 px-8 pt-2 pb-16">
              <p class="text-2xl font-medium text-center sm:text-justify">
                Make decisions based on real-time insights and predictions from
                Vetsark
              </p>
            </div>
          </div>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import BackgroundLine from '../Icons/backgroundLine.vue'
export default {
  components: { BackgroundLine }
}
</script>

<style scoped>
h1 {
  font-family: 'Comfortaa', cursive;
}
</style>
