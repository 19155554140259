import Swal from 'sweetalert2'
import fb from '../../services/firebase'

export const namespaced = true

export const state = {
  client: {},
  client_pet: [],
  clients: [],
  clients_list_for_dashboard: [],
  client_count: null,
  loading: false,
  dashboard_client_loader: false
}

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_DASHBOARD_LOADER(state, value) {
    state.dashboard_client_loader = value
  },
  GET_CLIENTS(state, payload) {
    state.clients = payload
  },
  GET_CLIENT(state, payload) {
    state.client = payload
  },
  GET_CLIENTS_FOR_DASHBOARD(state, payload) {
    state.clients_list_for_dashboard = payload
  },
  GET_CLIENT_PET(state, payload) {
    state.client_pet = payload
  },
  GET_CLIENT_COUNT(state, payload) {
    state.client_count = payload
  },
  UPDATE_CLIENT(state, payload) {
    state.client = payload
  },
  DISABLE_CLIENT(state, payload) {
    state.client = payload
  }
}

export const actions = {
  getClient({ commit }) {
    commit('SET_LOADING', true)
    commit('GET_CLIENTS', [])

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('clients')
                .where('hospital_id', '==', hospital_id)
                .onSnapshot(querySnapshot => {
                  var container = []

                  querySnapshot.forEach(doc => {
                    var client = doc.data()
                    client.id = doc.id
                    container.push(client)
                  })
                  commit('GET_CLIENTS', container)
                  commit('SET_LOADING', false)
                })
            } else {
              commit('SET_LOADING', false)
            }
          })
      } else {
        commit('SET_LOADING', false)
      }
    })
  },

  getClientforDashboard({ commit }) {
    commit('SET_LOADING', true)
    commit('GET_CLIENTS', [])
    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            var hospital_id = doc.data().hospital_id

            fb.db
              .collection('clients')
              .where('hospital_id', '==', hospital_id)
              .orderBy('updatedAt', 'desc')
              .limit(6)
              .onSnapshot(querySnapshot => {
                var container = []
                querySnapshot.forEach(doc => {
                  var client = doc.data()
                  client.id = doc.id
                  container.push(client)
                })
                commit('GET_CLIENTS_FOR_DASHBOARD', container)
                commit('SET_LOADING', false)
              })
          })
      } else {
        commit('SET_LOADING', false)
      }
    })
  },

  read({ commit }, payload) {
    commit('SET_LOADING', true)
    fb.db
      .collection('clients')
      .doc(payload)
      .onSnapshot(doc => {
        if (doc.exists) {
          var client_details = doc.data()
          client_details.id = doc.id

          commit('GET_CLIENT', client_details)
          commit('SET_LOADING', false)
        } else {
          commit('SET_LOADING', false)
        }
      })
  },

  clientPet({ commit }, payload) {
    commit('SET_LOADING', true)
    commit('GET_CLIENT_PET', [])

    fb.db
      .collection('clients')
      .doc(payload)
      .onSnapshot(doc => {
        var client = doc.data().client_details
        client.id = doc.id

        fb.db
          .collection('pets')
          .where('client_id', '==', payload)
          .onSnapshot(querySnapshot => {
            var container = []

            querySnapshot.forEach(doc => {
              var pet_details = doc.data()
              pet_details.id = doc.id

              pet_details.client = client
              container.push(pet_details)

              commit('GET_CLIENT_PET', container)
              commit('SET_LOADING', false)
            })
          })
      })
  },

  updateClient({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id
    return fb.db
      .collection('clients')
      .doc(id)
      .update({
        client_details: payload.client,
        updatedAt: payload.updatedAt
      })
      .then(() => {
        commit('SET_LOADING', false)
      })
      .catch(error => {
        console.log(error)
        Swal.fire('Oops!', 'Something went wrong')
      })
  },

  disableClient({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id
    delete payload.id
    return fb.db
      .collection('clients')
      .doc(id)
      .update({
        isActive: false
      })
      .then(() => {
        commit('SET_LOADING', false)
      })
      .catch(error => {
        console.log(error)
        Swal.fire('Oops!', 'Something went wrong')
      })
  },

  clientCount({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('clients')
                .where('hospital_id', '==', hospital_id)
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var client_count = doc.docs.length

                    commit('GET_CLIENT_COUNT', client_count)
                    commit('SET_LOADING', false)
                  }
                  commit('SET_LOADING', false)
                })
              commit('SET_LOADING', false)
            }
          })
        commit('SET_LOADING', false)
      }
    })
  }
}

export const getters = {}
