<template>
  <main class="bg-background_wave5 w-full h-full pt-24 pb-16">
    <section class="w-4/5 mx-auto space-y-12 sm:space-y-20">
      <section>
        <h1 class="text-center text-2xl sm:text-4xl lead_text">Contact Us</h1>
      </section>
      <section
        class="
          lg:flex
          w-full
          lg:space-x-48
          space-y-10
          md:space-y-14
          lg:space-y-0
        "
      >
        <section class="lg:w-2/6">
          <div class="sm:text-xl text-lg mb-8">
            Do you have any message or enquiry, kindly fill out this brief form
            and we will get back to you as soon as possible.
          </div>
          <div class="sm:text-xl text-lg">
            4 Gani Adebayo Close, Off Lekki Epe Expressway, Lagos, Nigeria.
          </div>
        </section>
        <section class="lg:w-8/12">
          <div class="flex flex-col space-y-4">
            <input
              type="text"
              placeholder="Name"
              class="
                p-3
                outline-none
                w-full
                bg-white
                shadow-lg
                border border-t-0 border-l-0 border-r-0
                hover:border-b-4 hover:border-primary
              "
            />
            <input
              type="email"
              placeholder="Email"
              class="
                p-3
                outline-none
                w-full
                bg-white
                shadow-lg
                border border-t-0 border-l-0 border-r-0
                hover:border-b-4 hover:border-primary
              "
            />
            <input
              type="tel"
              placeholder="Phone"
              class="
                p-3
                outline-none
                w-full
                bg-white
                shadow-lg
                border border-t-0 border-l-0 border-r-0
                hover:border-b-4 hover:border-primary
              "
            />
            <textarea
              placeholder="Message"
              cols="10"
              rows="10"
              class="
                p-4
                outline-none
                w-full
                bg-white
                shadow-lg
                border border-t-0 border-l-0 border-r-0
                hover:border-b-4 hover:border-primary
              "
            ></textarea>
          </div>
          <div class="mt-6">
            <button class="py-4 px-14 rounded-full bg-primary text-white">
              Submit
            </button>
          </div>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
export default {
  components: {}
}
</script>

<style scoped>
.lead_text {
  font-family: 'Comfortaa', cursive;
}
</style>
