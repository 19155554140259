<template>
  <section class="bg-background_color w-full h-full bg-opacity-50 pt-14">
    <div>
      <h1 class="text-center text-2xl sm:text-4xl mb-10">
        Alpha Prime enables <br class="block sm:hidden" />
        you to
      </h1>
    </div>
    <section>
      <div>
        <wave-one></wave-one>
      </div>
      <section
        class="
          bg-background_purple
          sm:pb-36
          md:pb-0
          w-full
          h-full
          md:grid md:grid-cols-2
        "
      >
        <div class="col-span-1 hidden sm:block">
          <img src="/img/Go digital.png" alt="digital wallent" />
        </div>
        <div class="col-span-1">
          <div
            class="
              w-full
              xl:w-9/12
              h-full
              space-y-4
              pt-16
              lg:pt-24
              xxl:pt-40
              px-8
              text-center
              md:text-justify
              space-y-8
            "
          >
            <h2 class="xl:text-5xl font-semibold text-3xl">
              Go digital, effortlessly
            </h2>
            <p class="text-xl xl:text-3xl font-medium lg:pr-20 xl:pr-0">
              Almost 90% of animal healthcare professionals have load of paper
              files. Alpha Prime allows you store and retrieve your data on the
              go to improve your practice and profits.
            </p>
          </div>
        </div>
        <div class="sm:hidden mt-14">
          <img src="/img/Go digital.png" alt="digital wallent" />
        </div>
      </section>
    </section>
    <section class="-mt-0">
      <div class="hidden">
        <wave-two></wave-two>
      </div>
      <section class="bg-background_blue w-full h-full md:grid md:grid-cols-2">
        <div class="col-span-1 flex justify-end">
          <div
            class="
              w-full
              xl:w-10/12
              h-full
              space-y-4
              pt-24
              lg:pt-36
              xxl:pt-52
              px-8
              lg:px-16
              xl:px-8
              text-center
              md:text-justify
              space-y-8
              text-white
            "
          >
            <h2 class="xl:text-5xl font-semibold text-3xl">
              Streamline your workflow
            </h2>
            <p class="text-xl xl:text-3xl font-medium xl:pr-10">
              By simply using Alpha Prime you can manage your doctors (staff),
              streamline your workflow, and boost your clinical productivity by
              over 100%.
            </p>
          </div>
        </div>
        <div class="col-span-1 flex justify-end mt-14">
          <img src="/img/Gain business insight.png" alt="Business insight" />
        </div>
      </section>
    </section>
    <section class="-mt-0">
      <div class="hidden">
        <wave-three></wave-three>
      </div>
      <section
        class="bg-background_wave3 w-full h-full md:grid md:grid-cols-2 pt-10"
      >
        <div class="col-span-1 hidden md:block">
          <img
            src="/img/Start reporting today.png"
            alt="Start reporting today"
          />
        </div>
        <div class="col-span-1">
          <div
            class="
              w-full
              xl:w-9/12
              h-full
              space-y-4
              pt-16
              lg:pt-24
              xxl:pt-40
              px-8
              text-center
              md:text-justify
              space-y-8
              text-white
            "
          >
            <h2 class="xl:text-5xl font-semibold text-3xl">
              Start reporting, today
            </h2>
            <p class="text-xl xl:text-3xl font-medium lg:pr-20 xl:pr-0">
              Most veterinarians struggle to report diseases to relevant
              authorities. Alpha Prime simplifies disease reporting by 1000x
            </p>
          </div>
        </div>
        <div class="md:hidden mt-20">
          <img
            src="/img/Start reporting today.png"
            alt="Start reporting today"
          />
        </div>
      </section>
    </section>
    <section class="-mt-0">
      <div class="hidden">
        <wave-four></wave-four>
      </div>
      <section
        class="bg-background_wave4 w-full h-full md:grid md:grid-cols-2 pt-10"
      >
        <div class="col-span-1 flex justify-end">
          <div
            class="
              w-full
              xl:w-10/12
              h-full
              space-y-4
              pt-16
              lg:pt-24
              px-8
              lg:px-16
              xxl:pt-40
              xl:px-8
              text-center
              md:text-justify
              space-y-8
              text-white
            "
          >
            <h2 class="xl:text-5xl font-semibold text-3xl">
              Organize your Disease cases
            </h2>
            <p class="text-xl xl:text-3xl font-medium xl:pr-10">
              Become part of a vibrant community of professionals who play an
              active role in tracking their disease cases. Understand the data
              behind disease transmission and prevention.
            </p>
          </div>
        </div>
        <div class="col-span-1 flex justify-end mt-14 md:mt-0">
          <img
            src="/img/Simplify disease surveilance.png"
            alt="Business insight"
          />
        </div>
      </section>
    </section>
    <section class="-mt-0">
      <div class="hidden">
        <wave-five></wave-five>
      </div>
      <section class="bg-background_wave5 w-full h-full">
        <div class="space-y-5 sm:space-y-2 pt-36 pb-14 px-10">
          <h1 class="text-center text-2xl lg:text-4xl">
            Experience Alpha Prime from any device
          </h1>
          <p class="text-center text-xl lg:text-2xl">
            The ease &amp; simplicity of Alpha Prime from wherever you are -
            office, clinic or on the go.
          </p>
        </div>
        <div class="w-4/5 lg:w-2/5 h-full mx-auto">
          <div class="flex">
            <div class="">
              <img src="img/web app.png" alt="" />
            </div>
            <div class="mt-12 -ml-20 sm:mt-10 sm:-ml-36 md:mt-20 md:-ml-52">
              <img src="img/mobile app.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import WaveFive from '../Icons/waveFive.vue'
import WaveFour from '../Icons/waveFour.vue'
import waveOne from '../Icons/waveOne.vue'
import WaveThree from '../Icons/waveThree.vue'
import WaveTwo from '../Icons/waveTwo.vue'
export default {
  components: { waveOne, WaveTwo, WaveThree, WaveFour, WaveFive }
}
</script>

<style scoped>
h1 {
  font-family: 'Comfortaa', cursive;
}
</style>