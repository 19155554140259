import Swal from 'sweetalert2'
import fb from '../../services/firebase'

export const namespaced = true

export const state = {
  pet: {},
  client: {},
  farm: {},
  loading: false
}

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_PET(state, payload) {
    state.pet = payload
  },
  SET_FARM(state, payload) {
    state.farm = payload
  },
  SET_CLIENT(state, payload) {
    state.client = payload
  }
}

export const actions = {
  createClient({ commit }, payload) {
    commit('SET_LOADING', true)
    var client_id = payload.id
    delete payload.id
    return fb.db
      .collection('clients')
      .doc(client_id)
      .set(payload)
      .then(() => {
        commit('SET_LOADING', false)
        commit('SET_CLIENT', payload)
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')
        commit('SET_LOADING', false)
      })
  },

  createPet({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id
    delete payload.id
    fb.db
      .collection('pets')
      .doc(id)
      .set(payload)
      .then(() => {
        commit('SET_PET', payload)
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')
        commit('SET_LOADING', false)
      })
  },

  createFarm({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id
    delete payload.id
    fb.db
      .collection('farms')
      .doc(id)
      .set(payload)
      .then(() => {
        commit('SET_FARM', payload)
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')
        commit('SET_LOADING', false)
      })
  }
}

export const getters = {}
