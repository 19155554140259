// import Swal from 'sweetalert2'
import fb from '../../services/firebase'

export const namespaced = true

export const state = {
  farm: {},
  loading: false
}

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value
  },
  GET_FARM(state, payload) {
    state.farm = payload
  }
}

export const actions = {
  setFarm({ commit }, farm) {
    commit('GET_FARM', [])
    var farm_info = {}
    fb.db
      .collection('farms')
      .doc(farm.id)
      .onSnapshot(doc => {
        if (doc.exists) {
          farm_info = doc.data()
          farm_info.id = doc.id
        }
        commit('GET_FARM', farm_info)
      })
  }
}

export const getters = {}
