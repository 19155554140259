<template>
  <div>
    <svg
      fill="none"
      viewBox="0 0 13 12"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height, color]"
    >
      <path
        d="m12.47 7.3673v4.5018h-2.572v-4.2003c0-1.0552-0.37201-1.7753-1.303-1.7753-0.71068 0-1.1336 0.48531-1.3197 0.95475-0.06785 0.16783-0.08531 0.40142-0.08531 0.63634v4.3845h-2.5729s0.03466-7.114 0-7.8511h2.5726v1.1129c-0.00522 0.00832-0.012 0.0173-0.01691 0.02537h0.01691v-0.02537c0.34179-0.53419 0.9522-1.2973 2.3185-1.2973 1.6928-3e-5 2.9617 1.1221 2.9617 3.5337zm-10.544-7.1339c-0.88017 0-1.4559 0.58583-1.4559 1.3562 0 0.7536 0.55904 1.3572 1.4217 1.3572h0.01719c0.89721 0 1.4552-0.60349 1.4552-1.3572-0.01691-0.77039-0.55799-1.3562-1.4382-1.3562zm-1.3031 11.636h2.5719v-7.8511h-2.5719v7.8511z"
        fill="#1B2CC1"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-7'
    },
    height: {
      type: String,
      default: 'h-7'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
