<template>
  <div>
    <svg
      fill="none"
      viewBox="0 0 8 13"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height, color]"
    >
      <path
        d="m7.2254 0.83746-1.5561-0.002497c-1.7482 0-2.878 1.1591-2.878 2.9532v1.3616h-1.5646c-0.1352 0-0.24469 0.10961-0.24469 0.24481v1.9728c0 0.1352 0.10961 0.24468 0.24469 0.24468h1.5646v4.9782c0 0.1352 0.10949 0.2447 0.24469 0.2447h2.0414c0.1352 0 0.24468-0.1096 0.24468-0.2447v-4.9782h1.8294c0.1352 0 0.24469-0.10948 0.24469-0.24468l7.5e-4 -1.9728c0-0.06492-0.02585-0.12709-0.07166-0.17303-0.04582-0.04594-0.10824-0.07178-0.17315-0.07178h-1.83v-1.1543c0-0.55479 0.13221-0.83643 0.8549-0.83643l1.0483-3.8e-4c0.13508 0 0.24456-0.10961 0.24456-0.24468v-1.8319c0-0.13495-0.10936-0.24443-0.24431-0.24468z"
        fill="#1B2CC1"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-7'
    },
    height: {
      type: String,
      default: 'h-7'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
