<template>
  <main class="bg-home_footer_bg w-full h-full pt-20 sm:pt-28">
    <section class="w-4/5 mx-auto">
      <section
        class="grid grid-cols-1 lg:grid-cols-4 mb-12 space-y-10 lg:space-y-0"
      >
        <div class="space-y-2">
          <img src="/img/Logo_colour.png" alt="brand logo" class="w-32" />
          <div class="flex gap-5">
            <facebook :width="'w-4'" :height="'h-4'"></facebook>
            <twitter :width="'w-4'" :height="'h-4'"></twitter>
            <linked-in :width="'w-4'" :height="'h-4'"></linked-in>
            <instagram :width="'w-4'" :height="'h-4'"></instagram>
          </div>
        </div>
        <div>
          <ul class="space-y-2">
            <li class="text-xl font-medium">COMPANY</li>
            <li>About us</li>
            <li>Press</li>
            <li>Careers</li>
          </ul>
        </div>
        <div>
          <ul class="space-y-2">
            <li class="text-xl font-medium">MORE</li>
            <li>FAQ</li>
            <li>Blog</li>
            <li>Contact us</li>
          </ul>
        </div>
        <div class="space-y-4">
          <div class="flex space-x-4 items-center">
            <location-icon :width="'w-5'" :height="'h-5'"></location-icon>
            <div class="text-lg">
              4 Gani Adebayo Close, Off Lekki Epe Expressway, Lagos, Nigeria.
            </div>
          </div>
          <div class="flex space-x-4 items-center">
            <mail-icon :width="'w-5'" :height="'h-5'"></mail-icon>
            <div class="text-lg">info@vetsark.com</div>
          </div>
          <div class="flex space-x-4 items-center">
            <phone-icon :width="'w-5'" :height="'h-5'"></phone-icon>
            <div class="text-lg">+2348168008052, +2348182439347</div>
          </div>
        </div>
      </section>
      <section
        class="
          border border-t border-b-0 border-r-0 border-l-0 border-gray-300
          py-4
        "
      >
        <div class="flex justify-center text-gray-300">
          Copyright, Vetsark {{ year() }}
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import Facebook from '../Icons/facebook.vue'
import Instagram from '../Icons/instagram.vue'
import LinkedIn from '../Icons/LinkedIn.vue'
import LocationIcon from '../Icons/locationIcon.vue'
import MailIcon from '../Icons/mailIcon.vue'
import PhoneIcon from '../Icons/phoneIcon.vue'
import twitter from '../Icons/twitter.vue'
export default {
  components: {
    Facebook,
    twitter,
    LinkedIn,
    Instagram,
    LocationIcon,
    MailIcon,
    PhoneIcon
  },
  methods: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style></style>
