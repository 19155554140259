import router from '../../../router'
import Swal from 'sweetalert2'
import fb from '../../../services/firebase'
import store from '../..'

export const namespaced = true

export const state = {
  reports: [],
  pet: {},
  report: {},
  loading: false,
  doc_id: null,
  finished_at: null,
  index: 1
}

export const mutations = {
  GET_PET(state, payload) {
    state.pet = payload
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  ADD_REPORT(state, payload) {
    state.reports = payload
  },
  SET_REPORT(state, payload) {
    state.report = payload
  },
  UPDATE_FINISHED_AT(state, payload) {
    state.finished_at = payload
  }
}

export const actions = {
  // get a single pet and pet's owner details
  getPet({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload

    fb.db
      .collection('pets')
      .doc(id)
      .onSnapshot(doc => {
        if (doc.exists) {
          var pet_details = doc.data()
          var client_id = pet_details.client_id

          fb.db
            .collection('clients')
            .doc(client_id)
            .onSnapshot(doc => {
              var client_details = doc.data().client_details
              pet_details.client_details = client_details

              commit('GET_PET', pet_details)
              commit('SET_LOADING', false)
            })
        } else {
          commit('SET_LOADING', false)
        }
      })
  },

  // validate report if it exist or not
  validator({ commit }, payload) {
    commit('SET_LOADING', true)
    var pet_id = payload.pet_id

    fb.db
      .collection('reports')
      .where('pet_id', '==', pet_id)
      .where('finishedAt', '==', false)
      .get()
      .then(doc => {
        if (doc.empty == false) {
          Swal.fire({
            icon: 'info',
            title: 'info',
            text: 'Pet report exist!',
            showDenyButton: true,
            confirmButtonText: `Continue report`,
            denyButtonText: `Discard existing report and create a new report`
          }).then(result => {
            if (result.isConfirmed) {
              router.push({
                name: 'AddReport',
                params: {
                  pet_id: payload.pet_id,
                  report_id: doc.docs[0].id,
                  index: state.index
                }
              })
            } else if (result.isDenied) {
              store.dispatch('reports/newReportwithexistingID', doc.docs[0])
            }
          })
        } else if (doc.empty == true) {
          store.dispatch('reports/write', payload)
        }
        commit('SET_LOADING', false)
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')

        commit('SET_LOADING', false)
      })
  },

  // create new report and also update pet.isActive to true
  write({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id
    delete payload.id
    var pet_id = payload.pet_id

    // register report
    fb.db
      .collection('reports')
      .doc(id)
      .set(payload)
      .then(() => {
        commit('SET_LOADING', false)
        commit('ADD_REPORT', payload)
        router.push({
          name: 'AddReport',
          params: { pet_id: pet_id, report_id: id, index: state.index }
        })

        fb.db
          .collection('pets')
          .doc(pet_id)
          .update({ isActive: true })
          .then(() => {})
          .catch(error => {
            var errorCode = error.code
            var errorMsg = error.message
            Swal.fire(errorCode, errorMsg, 'error')
          })
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')

        commit('SET_LOADING', false)
      })
  },

  // use existing report id to create new report
  newReportwithexistingID({ commit }, payload) {
    var id = payload.id
    var data = payload.data()

    commit('SET_LOADING', true)

    fb.db
      .collection('reports')
      .doc(id)
      .delete()
      .then(() => {
        // create new report with existing id
        fb.db
          .collection('reports')
          .doc(id)
          .set(data)
          .then(() => {
            commit('SET_REPORT', data)
            router.push({
              name: 'AddReport',
              params: {
                pet_id: data.pet_id,
                report_id: id,
                index: state.index
              }
            })
            commit('SET_LOADING', false)
          })
          .catch(error => {
            var errorCode = error.code
            var errorMsg = error.message
            Swal.fire(errorCode, errorMsg, 'error')

            commit('SET_LOADING', false)
          })
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')
      })
  },

  // get a single report from database
  getReport({ commit }, payload) {
    commit('SET_LOADING', true)

    fb.db
      .collection('reports')
      .doc(payload)
      .onSnapshot(doc => {
        var container = doc.data()
        commit('SET_REPORT', container)
      })
    commit('SET_LOADING', false)
  },

  finishedReport({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id
    delete payload.id

    return fb.db
      .collection('reports')
      .doc(id)
      .update({ finishedAt: true, updatedAt: Date.now() })
      .then(() => {
        fb.db
          .collection('pets')
          .doc(payload.pet_id)
          .update({
            isActive: false
          })
          .then(() => {})
          .catch(error => {
            var errorCode = error.code
            var errorMsg = error.message
            Swal.fire(errorCode, errorMsg, 'error')
          })
        commit('SET_LOADING', false)
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')
      })
  },

  deleteReport({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id

    store.dispatch('reports/finishedReport', payload)

    fb.db
      .collection('reports')
      .doc(id)
      .delete()
      .then(() => {})
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')
      })
  }
}

export const getters = {}
