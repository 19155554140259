<template>
  <main class="bg-background_wave5 w-full h-full pt-24 pb-44">
    <section class="w-4/5 mx-auto space-y-16">
      <section>
        <h1 class="text-center text-2xl sm:text-4xl lead_text">In the news</h1>
      </section>

      <section>
        <div
          class="
            grid grid-cols-1
            sm:grid-cols-1
            md:grid-cols-2
            lg:grid-cols-2
            xl:grid-cols-3
            gap-16
            sm:gap-20
          "
        >
          <!--Card 1-->
          <div class="rounded overflow-hidden shadow-lg">
            <img class="w-full" src="img/Mask Group.jpg" alt="Live Stock" />
            <div class="py-6 px-10">
              <p class="text-gray-700 text-lg">
                Vetsark <br />
                Data science technology to protect crops and livestock from
                pests and diseases.
              </p>
            </div>
            <div class="px-10 pb-4 flex gap-4">
              <span>
                <favorite></favorite>
              </span>
              <span>
                <tweet></tweet>
              </span>
            </div>
          </div>
          <!--Card 2-->
          <div class="rounded overflow-hidden shadow-lg">
            <img
              class="w-full"
              src="img/Screenshot 2019-03-14 at 5.49.jpg"
              alt="River"
            />
            <div class="px-10 py-6">
              <p class="text-gray-700 text-lg">
                Catalysing actionable knowledge for youth entrepreneurship:
                Experience of Vetsark (Nigeria)
              </p>
            </div>
            <div class="px-10 pb-4 flex gap-4">
              <span>
                <favorite></favorite>
              </span>
              <span>
                <tweet></tweet>
              </span>
            </div>
          </div>

          <!--Card 3-->
          <div class="rounded overflow-hidden shadow-lg">
            <div
              class="
                py-14
                xl:py-0
                px-12
                w-full
                h-full
                flex flex-col
                justify-center
              "
            >
              <p class="text-gray-700 text-lg text-center md:text-justify">
                Want to see more of what’s happening at Vetsark? click the
                button below to visit our blog.
              </p>
              <div class="flex justify-center mt-8">
                <a
                  href="javascript:;"
                  class="block bg-primary text-white px-8 py-3 rounded-full"
                  >View Blog</a
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import favorite from '../Icons/favorite.vue'
import Tweet from '../Icons/tweet.vue'
export default {
  components: { favorite, Tweet }
}
</script>

<style></style>
