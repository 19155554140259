import fb from '../../services/firebase'
import router from '../../router'
// import Swal from 'sweetalert2'

export const namespaced = true

export const state = {
  loading: false,
  isUserLoggedIn: false
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_USER_LOGGED(state, payload) {
    state.isUserLoggedIn = payload
  }
}

export const actions = {
  isLogged({ commit }) {
    commit('SET_LOADING', true)
    fb.auth.onAuthStateChanged(user => {
      if (user) {
        if (
          router.currentRoute.name == 'Register' ||
          router.currentRoute.name == 'Login'
        ) {
          router.push({
            name: 'Dashboard'
          })
        } else {
          fb.db
            .collection('users')
            .doc(user.uid)
            .onSnapshot(doc => {
              if (
                doc.data().isApproved == false &&
                router.currentRoute.name != 'Welcome'
              ) {
                router.push({
                  name: 'Welcome'
                })
              } else if (
                doc.data().isApproved != false &&
                router.currentRoute.name == 'Welcome' &&
                router.currentRoute.name != 'Dashboard'
              ) {
                router.push({
                  name: 'Dashboard'
                })
              }
            })
        }
      } else {
        if (
          router.currentRoute.name != 'Register' &&
          router.currentRoute.name != 'Login'
        ) {
          router.push({
            name: 'Register'
          })
        }
      }
    })
  }
}

export const getters = {}
