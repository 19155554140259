import Swal from 'sweetalert2'
import fb from '../../services/firebase'

export const namespaced = true

export const state = {
  pets: [],
  active_pets: [],
  non_active_pets: [],
  pet: {},
  cat: null,
  dog: null,
  ferret: null,
  pig: null,
  rat: null,
  mice: null,
  rabbit: null,
  bird: null,
  amphibians: null,
  fish: null,
  cattle: null,
  guinea_Pig: null,
  horse: null,
  isLoading: false
}

export const mutations = {
  SET_LOADING(state, value) {
    state.isLoading = value
  },
  GET_PETS(state, payload) {
    state.pets = payload
  },

  GET_CAT(state, payload) {
    state.cat = payload
  },
  GET_DOG(state, payload) {
    state.dog = payload
  },
  GET_CATTLE(state, payload) {
    state.cattle = payload
  },
  GET_FERRET(state, payload) {
    state.ferret = payload
  },
  GET_PIG(state, payload) {
    state.pig = payload
  },
  GET_RAT(state, payload) {
    state.rat = payload
  },
  GET_RABBIT(state, payload) {
    state.rabbit = payload
  },
  GET_BIRD(state, payload) {
    state.bird = payload
  },
  GET_FISH(state, payload) {
    state.fish = payload
  },
  GET_AMPHIBIANS(state, payload) {
    state.amphibians = payload
  },
  GET_GUINEA_PIG(state, payload) {
    state.guinea_Pig = payload
  },
  GET_MICE(state, payload) {
    state.mice = payload
  },
  GET_HORSE(state, payload) {
    state.horse = payload
  },

  GET_ACTIVE_PET(state, payload) {
    state.active_pets = payload
  },
  GET_NONACTIVE_PET(state, payload) {
    state.non_active_pets = payload
  },
  UPDATE_PET(state, payload) {
    state.pet = payload
  },
  DELETE_PET(state, payload) {
    state.pet = payload
  }
}

export const actions = {
  // get pets activity
  getPets({ commit }) {
    commit('SET_LOADING', true)
    commit('GET_PETS', [])

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .onSnapshot(querySnapshot => {
                  var container = []
                  querySnapshot.forEach(doc => {
                    var pet = doc.data()
                    pet.id = doc.id
                    var client_id = pet.client_id
                    fb.db
                      .collection('clients')
                      .doc(client_id)
                      .get()
                      .then(doc => {
                        var client = doc.data().client_details
                        pet.client = client
                        container.push(pet)
                        commit('GET_PETS', container)
                        commit('SET_LOADING', false)
                      })
                  })
                })
              commit('SET_LOADING', false)
            } else {
              commit('SET_LOADING', false)
            }
          })
      }
      commit('SET_LOADING', false)
    })
  },

  // active pet
  getActivePet({ commit }) {
    commit('SET_LOADING', true)
    commit('GET_ACTIVE_PET', [])
    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('isActive', '==', true)
                .onSnapshot(querySnapshot => {
                  var container = []
                  querySnapshot.forEach(doc => {
                    var pet = doc.data()
                    pet.id = doc.id
                    var client_id = pet.client_id
                    fb.db
                      .collection('clients')
                      .doc(client_id)
                      .onSnapshot(doc => {
                        var client = doc.data().client_details
                        pet.client = client
                        container.push(pet)
                        commit('GET_ACTIVE_PET', container)
                        commit('SET_LOADING', false)
                      })
                  })
                })
              commit('SET_LOADING', false)
            } else {
              commit('SET_LOADING', false)
            }
          })
      }
      commit('SET_LOADING', false)
    })
  },

  // non active pet
  getNonActivePet({ commit }) {
    commit('SET_LOADING', true)
    commit('GET_ACTIVE_PET', [])

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('isActive', '==', false)
                .onSnapshot(querySnapshot => {
                  var container = []
                  querySnapshot.forEach(doc => {
                    var pet = doc.data()
                    pet.id = doc.id
                    var client_id = pet.client_id
                    fb.db
                      .collection('clients')
                      .doc(client_id)
                      .onSnapshot(doc => {
                        var client = doc.data().client_details
                        pet.client = client
                        container.push(pet)
                        commit('GET_NONACTIVE_PET', container)
                        commit('SET_LOADING', false)
                      })
                  })
                })
              commit('SET_LOADING', false)
            } else {
              commit('SET_LOADING', false)
            }
          })
      }
      commit('SET_LOADING', false)
    })
  },

  // update pet
  updatePet({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id

    return fb.db
      .collection('pets')
      .doc(id)
      .update({
        pet_info: payload.pet
      })
      .then(() => {
        commit('SET_LOADING', false)
      })
      .catch(error => {
        console.log(error)
        Swal.fire('Oops!', 'Something went wrong')
      })
  },

  // pet type
  petCat({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Cat')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var cat = doc.docs.length
                    commit('GET_CAT', cat)
                    commit('SET_LOADING', false)
                  } else {
                    commit('SET_LOADING', false)
                  }
                })
              commit('SET_LOADING', false)
            }
          })
        commit('SET_LOADING', false)
      }
    })
  },

  petDog({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Dog')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var dog = doc.docs.length

                    commit('GET_DOG', dog)
                    commit('SET_LOADING', false)
                  }
                })
              commit('SET_LOADING', false)
            }
          })
        commit('SET_LOADING', false)
      }
    })
  },

  petCattle({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Cattle')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var cattle = doc.docs.length

                    commit('GET_CATTLE', cattle)
                    commit('SET_LOADING', false)
                  }
                  commit('SET_LOADING', false)
                })
              commit('SET_LOADING', false)
            }
          })
        commit('SET_LOADING', false)
      }
    })
  },

  petRabbit({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Rabbit')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var rabbit = doc.docs.length

                    commit('GET_RABBIT', rabbit)
                    commit('SET_LOADING', false)
                  }
                  commit('SET_LOADING', false)
                })
              commit('SET_LOADING', false)
            }
          })
        commit('SET_LOADING', false)
      }
    })
  },

  petRats({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Rats')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var rat = doc.docs.length

                    commit('GET_RAT', rat)
                    commit('SET_LOADING', false)
                  }
                })
              commit('SET_LOADING', false)
            }
          })
        commit('SET_LOADING', false)
      }
    })
  },

  petFerret({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Ferret')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var ferret = doc.docs.length

                    commit('GET_FERRET', ferret)
                    commit('SET_LOADING', false)
                  }
                })
              commit('SET_LOADING', false)
            }
          })
        commit('SET_LOADING', false)
      }
    })
  },

  petPig({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Pig')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var pig = doc.docs.length

                    commit('GET_PIG', pig)
                    commit('SET_LOADING', false)
                  }
                })
              commit('SET_LOADING', false)
            }
          })
        commit('SET_LOADING', false)
      }
    })
  },

  petGuineaPig({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Guinea Pig')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var Guinea_Pig = doc.docs.length

                    commit('GET_GUINEA_PIG', Guinea_Pig)
                    commit('SET_LOADING', false)
                  }
                })
              commit('SET_LOADING', false)
            }
          })

        commit('SET_LOADING', false)
      }
    })
  },

  petMice({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Mice')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var mice = doc.docs.length

                    commit('GET_MICE', mice)
                    commit('SET_LOADING', false)
                  }
                })
            }
            commit('SET_LOADING', false)
          })
      }
      commit('SET_LOADING', false)
    })
  },

  petAmphibians({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Amphibians')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    console.log(doc)
                    var amphibians = doc.docs.length

                    commit('GET_AMPHIBIANS', amphibians)
                    commit('SET_LOADING', false)
                  }
                  commit('SET_LOADING', false)
                })
            }
            commit('SET_LOADING', false)
          })
      }
      commit('SET_LOADING', false)
    })
  },

  petFish({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Fish')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var fish = doc.docs.length

                    commit('GET_FISH', fish)
                    commit('SET_LOADING', false)
                  }
                  commit('SET_LOADING', false)
                })
            }
            commit('SET_LOADING', false)
          })
      }
      commit('SET_LOADING', false)
    })
  },

  petBird({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Bird')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var bird = doc.docs.length

                    commit('GET_BIRD', bird)
                    commit('SET_LOADING', false)
                  }
                  commit('SET_LOADING', false)
                })
            }
            commit('SET_LOADING', false)
          })
      }
      commit('SET_LOADING', false)
    })
  },

  petHorse({ commit }) {
    commit('SET_LOADING', true)

    fb.auth.onAuthStateChanged(user => {
      if (user) {
        fb.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              var hospital_id = doc.data().hospital_id

              fb.db
                .collection('pets')
                .where('hospital_id', '==', hospital_id)
                .where('pet_info.pet_type', '==', 'Horse')
                .onSnapshot(doc => {
                  if (doc.empty == false) {
                    var horse = doc.docs.length

                    commit('GET_HORSE', horse)
                    commit('SET_LOADING', false)
                  }
                  commit('SET_LOADING', false)
                })
            }
            commit('SET_LOADING', false)
          })
      }
      commit('SET_LOADING', false)
    })
  }
}

export const getters = {}
