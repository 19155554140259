<template>
  <div>
    <svg
      width="450"
      height="833"
      viewBox="0 0 450 833"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-5.85428 283.133C-30.3634 193.85 -65.3765 242.868 -193.174 283.133C-198.426 290.136 -233.089 299.94 -329.725 283.133C-450.52 262.126 -429.512 375.918 -373.491 393.424C-317.47 410.931 -324.473 442.443 -329.725 568.49C-334.977 694.537 -308.717 712.043 -247.444 752.308C-186.171 792.573 -103.891 712.043 -32.1141 712.043C39.6626 712.043 121.943 789.072 172.712 796.074C223.481 803.077 393.294 799.576 416.053 726.048C438.811 652.521 310.499 598.251 310.499 559.736C310.499 521.222 360.032 538.729 370.536 507.217C381.04 475.705 347.777 417.934 272.499 393.424C197.221 368.915 158.707 375.918 99.1848 375.918C39.6626 375.918 18.6548 372.417 -5.85428 283.133Z"
        fill="#C9F4BD"
      />
      <g filter="url(#filter0_d)">
        <path
          d="M-376.971 73.4148C-374.622 62.6218 -363.968 55.7765 -353.175 58.1256L344.639 210.003C355.432 212.352 362.277 223.006 359.928 233.799L248.99 743.511L-487.909 583.127L-376.971 73.4148Z"
          fill="#E1E2E4"
        />
      </g>
      <g filter="url(#filter1_d)">
        <path
          d="M-526.188 551.246C-525.488 548.026 -522.31 545.984 -519.09 546.685L293.179 723.473C296.022 724.092 297.825 726.899 297.206 729.742C294.422 742.536 281.792 750.651 268.998 747.866L-508.597 578.624C-521.015 575.921 -528.891 563.664 -526.188 551.246Z"
          fill="#F6F7F9"
        />
      </g>
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="-465"
        y="79"
        width="811"
        height="616"
      >
        <rect
          x="-364.392"
          y="79.8231"
          width="726.842"
          height="470.57"
          transform="rotate(12.2788 -364.392 79.8231)"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M346.849 233.145L-347.714 81.9599L-448.388 544.466L246.175 695.652L346.849 233.145Z"
          fill="#FBFBFB"
        />
        <path
          d="M346.849 233.145L-347.714 81.9599L-357.677 127.729L336.886 278.914L346.849 233.145Z"
          fill="white"
        />
        <g filter="url(#filter2_d)">
          <circle cx="290.85" cy="245.983" r="12.2664" fill="#C8F2BC" />
        </g>
        <path
          d="M29.2498 332.215L-317.63 256.71L-320.602 270.36L26.2785 345.865L29.2498 332.215Z"
          fill="#E0E0E0"
        />
        <path
          d="M60.9333 515.37L25.6029 507.68L3.26649 610.296L38.5968 617.987L60.9333 515.37Z"
          fill="#F5F5F5"
        />
        <path
          d="M119.549 528.129L84.2191 520.439L61.8827 623.055L97.2131 630.746L119.549 528.129Z"
          fill="#F5F5F5"
        />
        <path
          d="M178.166 540.888L142.835 533.198L120.499 635.814L155.829 643.505L178.166 540.888Z"
          fill="#F5F5F5"
        />
        <path
          d="M236.782 553.647L201.452 545.957L179.115 648.573L214.446 656.264L236.782 553.647Z"
          fill="#F5F5F5"
        />
        <path
          d="M49.7654 566.679L14.4351 558.988L3.26685 610.296L38.5972 617.987L49.7654 566.679Z"
          fill="#2196F3"
        />
        <path
          opacity="0.4"
          d="M54.4274 545.259L19.097 537.568L14.2177 559.985L49.5481 567.675L54.4274 545.259Z"
          fill="#2196F3"
        />
        <path
          d="M105.957 590.576L70.6266 582.885L61.8829 623.055L97.2132 630.746L105.957 590.576Z"
          fill="#69F0AE"
        />
        <path
          opacity="0.4"
          d="M111.634 564.493L76.3036 556.803L70.6262 582.885L105.957 590.576L111.634 564.493Z"
          fill="#69F0AE"
        />
        <path
          d="M168.625 584.725L133.294 577.034L120.5 635.814L155.83 643.505L168.625 584.725Z"
          fill="#1B2CC1"
        />
        <path
          opacity="0.4"
          d="M173.07 564.301L137.74 556.611L133.294 577.034L168.625 584.725L173.07 564.301Z"
          fill="#1B2CC1"
        />
        <path
          d="M222.795 617.907L187.465 610.217L179.116 648.573L214.446 656.264L222.795 617.907Z"
          fill="#C6C6C6"
        />
        <path
          opacity="0.4"
          d="M225.397 605.952L190.067 598.261L187.465 610.217L222.795 617.907L225.397 605.952Z"
          fill="#D0D2D3"
        />
        <g filter="url(#filter3_d)">
          <path
            d="M126.626 424.631L126.902 424.935L253.451 310.038L253.175 309.734C237.938 292.952 216.659 282.911 194.019 281.818C171.379 280.726 149.233 288.671 132.451 303.908C115.67 319.144 105.628 340.423 104.536 363.063C103.443 385.703 111.389 407.85 126.626 424.631Z"
            fill="#1B2CC1"
          />
          <path
            d="M223.449 445.808C235.949 440.465 246.995 432.22 255.672 421.754C264.348 411.289 270.404 398.906 273.337 385.632C276.27 372.358 275.996 358.576 272.538 345.429C269.079 332.283 262.536 320.15 253.451 310.038L199.035 359.444L223.449 445.808Z"
            fill="#2196F3"
          />
          <path
            d="M126.902 424.936C138.854 437.98 154.559 446.994 171.851 450.733C189.143 454.473 207.169 452.754 223.443 445.814L199.035 359.444L126.902 424.936Z"
            fill="#69F0AE"
          />
        </g>
        <path
          d="M53.8414 466.242L44.4526 464.198L42.2518 474.309L51.6405 476.353L53.8414 466.242Z"
          fill="#2196F3"
        />
        <path
          d="M131.84 483.22L122.451 481.176L120.251 491.287L129.639 493.331L131.84 483.22Z"
          fill="#7C4DFF"
        />
        <path
          d="M209.839 500.198L200.45 498.154L198.25 508.265L207.638 510.309L209.839 500.198Z"
          fill="#69F0AE"
        />
        <path
          d="M88.7579 476.111L56.2584 469.037L55.0008 474.815L87.5003 481.889L88.7579 476.111Z"
          fill="#F5F5F5"
        />
        <path
          d="M167.479 493.246L134.979 486.172L133.722 491.95L166.221 499.024L167.479 493.246Z"
          fill="#F5F5F5"
        />
        <path
          d="M246.2 510.382L213.7 503.307L212.443 509.085L244.942 516.159L246.2 510.382Z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-558.909"
          y="-13.3361"
          width="1008.3"
          height="845.847"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="9" dy="9" />
          <feGaussianBlur stdDeviation="40" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="-576.719"
          y="470.547"
          width="984.047"
          height="361.866"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="30" dy="4" />
          <feGaussianBlur stdDeviation="40" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d"
          x="263.584"
          y="222.717"
          width="54.5328"
          height="54.5328"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.784314 0 0 0 0 0.94902 0 0 0 0 0.737255 0 0 0 0.63 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d"
          x="74.4365"
          y="260.719"
          width="230.915"
          height="230.946"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="9" />
          <feGaussianBlur stdDeviation="15" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.105882 0 0 0 0 0.172549 0 0 0 0 0.756863 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
