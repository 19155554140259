<template>
  <div>
    <svg
      fill="none"
      viewBox="0 0 11 14"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height, color]"
    >
      <path
        d="m5.4942 0.36816c-2.7567 0-5.0054 2.2486-5.0054 5.0162 0 0.33533 0.032434 0.67016 0.097303 0.99469 0 0.01277 0.003866 0.02553 0.006965 0.04124 0.002087 0.01031 0.003836 0.0216 0.003836 0.03437 0.55136 2.4754 4.4324 6.1944 4.5946 6.3458 0.08647 0.0869 0.29736 0.2634 0.29736 0.2634s0.22156-0.1873 0.30803-0.2634l0.03486-0.0344c0.42014-0.4114 4.008-3.9292 4.5381-6.2573 0-0.01081 0.0027-0.02161 0.0054-0.03241s0.0054-0.0216 0.0054-0.0324c0.0757-0.34613 0.1081-0.70257 0.1081-1.0595 0.0109-2.7676-2.2378-5.0162-4.9946-5.0162zm-0.00543 5.8872c0.80727 0 1.4617-0.65446 1.4617-1.4616 0-0.80715-0.65439-1.4616-1.4617-1.4616s-1.4617 0.65445-1.4617 1.4616c0 0.80714 0.65442 1.4616 1.4617 1.4616z"
        clip-rule="evenodd"
        fill="#1B2CC1"
        fill-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-7'
    },
    height: {
      type: String,
      default: 'h-7'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
