<template>
  <div>
    <svg
      fill="none"
      viewBox="0 0 13 11"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height, color]"
    >
      <path
        d="m12.47 2.1479c-0.4416 0.1986-0.9155 0.33329-1.4135 0.39342 0.5084-0.30895 0.8976-0.799 1.0821-1.3811-0.4769 0.28611-1.0033 0.49387-1.5642 0.60649-0.4492-0.48625-1.0881-0.78911-1.7967-0.78911-1.3595 0-2.4618 1.1186-2.4618 2.4974 0 0.19555 0.02174 0.38655 0.06374 0.5692-2.0456-0.10427-3.8595-1.0988-5.0735-2.6101-0.21222 0.3683-0.33294 0.79746-0.33294 1.2556 0 0.86673 0.43492 1.6315 1.0948 2.0789-0.40343-0.01369-0.78286-0.12631-1.115-0.31351v0.0312c0 1.2099 0.84884 2.2197 1.9744 2.4495-0.20621 0.05631-0.42366 0.08751-0.64862 0.08751-0.15896 0-0.31269-0.01597-0.46342-0.04642 0.31344 0.99304 1.2223 1.7152 2.2991 1.735-0.8421 0.66964-1.9039 1.0676-3.0572 1.0676-0.1987 0-0.39443-0.01217-0.58713-0.03424 1.0896 0.70999 2.3831 1.1239 3.7733 1.1239 4.5284 0 7.0037-3.8062 7.0037-7.1072l-0.0082-0.3234c0.4836-0.35007 0.902-0.78989 1.2312-1.2906z"
        fill="#1B2CC1"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-7'
    },
    height: {
      type: String,
      default: 'h-7'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
