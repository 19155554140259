import Swal from 'sweetalert2'
import fb from '../../services/firebase'

export const namespaced = true

export const state = {
  hospitals: [],
  hospital: {}
}

export const mutations = {
  GET_HOSPITAL(state, value) {
    state.hospitals = value
  },

  GET_SINGLE_HOSPITAL(state, value) {
    state.hospital = value
  }
}

export const actions = {
  getHospital({ commit }) {
    fb.db.collection('hospitals').onSnapshot(querySnapshot => {
      var container = []
      querySnapshot.forEach(doc => {
        var received = {
          id: doc.id,
          name: doc.data().name,
          address: doc.data.address
        }
        container.push(received)

        commit('GET_HOSPITAL', container)
      })
    })
  },

  getSingleHospital({ commit }, value) {
    fb.db
      .collection('hospitals')
      .doc(value)
      .get()
      .then(doc => {
        if (doc.exists) {
          var hospital = doc.data()

          commit('GET_SINGLE_HOSPITAL', hospital)
        }
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')
      })
  }
}

export const getters = {}
