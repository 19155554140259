<template>
  <header>
    <nav class="py-5 border-b border-gray-50 shadow-sm w-full">
      <ul class="flex items-center justify-between w-4/5 mx-auto">
        <div class="">
          <img src="/img/Logo_colour.png" alt="brand logo" class="w-24" />
        </div>
        <div class="sm:hidden block">
          <a
            href="javascript:;"
            @click="menuBtn()"
            class="rounded shadow py-2 px-2 bg-white"
          >
            <menu-icon :width="'w-6'" :height="'h-6'"></menu-icon>
          </a>
        </div>
        <div class="space-x-8 sm:flex items-center hidden">
          <router-link to="/" v-if="user_logged == false">FAQs</router-link>
          <router-link to="/login" v-if="user_logged == false"
            >Sign in</router-link
          >
          <router-link
            v-if="user_logged == false"
            to="/register"
            class="
              block
              bg-primary
              px-6
              py-2
              rounded-full
              shadow-md
              hover:shadow-xl
              text-white
            "
            >Sign Up</router-link
          >
          <router-link
            v-if="user_logged == true"
            to="/dashboard"
            class="
              bg-primary
              block
              rounded-full
              shadow-md
              py-2
              hover:shadow-xl
              text-white text-center
              w-28
            "
            >Dashboard</router-link
          >
        </div>
      </ul>
    </nav>
    <section class="w-full" v-if="show_menu">
      <div
        class="sm:hidden flex flex-col space-y-4 py-8 px-6 bg-white shadow-md"
      >
        <router-link to="/" v-if="user_logged == false">FAQs</router-link>
        <router-link to="/login" v-if="user_logged == false"
          >Sign in</router-link
        >
        <router-link
          v-if="user_logged == false"
          to="/register"
          class="
            bg-primary
            block
            rounded-full
            shadow-md
            py-2
            hover:shadow-xl
            text-white text-center
            w-28
          "
          >Sign Up</router-link
        >
        <router-link
          v-if="user_logged == true"
          to="/dashboard"
          class="
            bg-primary
            block
            rounded-full
            shadow-md
            py-2
            hover:shadow-xl
            text-white text-center
            w-28
          "
          >Dashboard</router-link
        >
      </div>
    </section>
  </header>
</template>

<script>
import MenuIcon from '../Icons/menuIcon.vue'
import fb from '../../services/firebase'
export default {
  components: {
    MenuIcon
  },
  data() {
    return {
      show_menu: false,
      user_logged: false,
      user_uid: null
    }
  },

  methods: {
    menuBtn() {
      this.show_menu = !this.show_menu
    }
  },

  mounted() {
    fb.auth.onAuthStateChanged(user => {
      if (user) {
        this.user_logged = true
        this.user_uid = user.uid
      } else {
        this.user_logged = false
      }
    })
  }
}
</script>

<style></style>
