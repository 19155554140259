<template>
  <div>
    <svg
      id="wave"
      style="transform: rotate(0deg); transition: 0.3s"
      viewBox="0 0 1440 190"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-4" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(76, 105, 168, 1)" offset="0%"></stop>
          <stop stop-color="rgba(76, 105, 168, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style="transform: translate(0, 0px); opacity: 1"
        fill="url(#sw-gradient-4)"
        d="M0,76L40,63.3C80,51,160,25,240,34.8C320,44,400,89,480,110.8C560,133,640,133,720,136.2C800,139,880,146,960,129.8C1040,114,1120,76,1200,69.7C1280,63,1360,89,1440,104.5C1520,120,1600,127,1680,129.8C1760,133,1840,133,1920,123.5C2000,114,2080,95,2160,79.2C2240,63,2320,51,2400,44.3C2480,38,2560,38,2640,34.8C2720,32,2800,25,2880,25.3C2960,25,3040,32,3120,47.5C3200,63,3280,89,3360,98.2C3440,108,3520,101,3600,85.5C3680,70,3760,44,3840,28.5C3920,13,4000,6,4080,22.2C4160,38,4240,76,4320,98.2C4400,120,4480,127,4560,107.7C4640,89,4720,44,4800,28.5C4880,13,4960,25,5040,31.7C5120,38,5200,38,5280,47.5C5360,57,5440,76,5520,69.7C5600,63,5680,32,5720,15.8L5760,0L5760,190L5720,190C5680,190,5600,190,5520,190C5440,190,5360,190,5280,190C5200,190,5120,190,5040,190C4960,190,4880,190,4800,190C4720,190,4640,190,4560,190C4480,190,4400,190,4320,190C4240,190,4160,190,4080,190C4000,190,3920,190,3840,190C3760,190,3680,190,3600,190C3520,190,3440,190,3360,190C3280,190,3200,190,3120,190C3040,190,2960,190,2880,190C2800,190,2720,190,2640,190C2560,190,2480,190,2400,190C2320,190,2240,190,2160,190C2080,190,2000,190,1920,190C1840,190,1760,190,1680,190C1600,190,1520,190,1440,190C1360,190,1280,190,1200,190C1120,190,1040,190,960,190C880,190,800,190,720,190C640,190,560,190,480,190C400,190,320,190,240,190C160,190,80,190,40,190L0,190Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
