import Swal from 'sweetalert2'
import fb from '../../../services/firebase'

export const namespaced = true

export const state = {
  loading: false
}

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value
  }
}

export const actions = {
  update({ commit }, payload) {
    commit('SET_LOADING', true)
    var id = payload.id
    delete payload.id
    fb.db
      .collection('reports')
      .doc(id)
      .update({ 'medical.referred': payload, updatedAt: Date.now() })
      .catch(error => {
        console.log(error)
        Swal.fire('Oops!', 'Something went wrong')
      })
  }
}

export const getters = {}
