<template>
  <div>
    <svg
      fill="none"
      viewBox="0 0 11 11"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height, color]"
    >
      <path
        d="m9.9332 7.7091c-0.66666 0-1.3333-0.11111-2-0.33333l-0.32297-0.10467-1.4548 1.438c-1.5555-0.77777-2.8333-2.1111-3.6667-3.6667l1.5068-1.4165s-0.128-0.31029-0.24057-0.63904c-0.16667-0.61111-0.21069-1-0.21069-1.6667v-0.55556h-3.0556v0.55556c0 5.2222 4.2222 9.4444 9.4444 9.4444h0.55559v-3.0555h-0.55559zm-0.55555-1.9444h1.1111c0-2.7778-2.2222-5-5-5v1.1111c2.1667 0 3.8889 1.7222 3.8889 3.8889zm-2.2222 0h1.1111c0-1.5556-1.2222-2.7778-2.7778-2.7778v1.1111c0.94444 0 1.6667 0.72223 1.6667 1.6667z"
        fill="#1B2CC1"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-7'
    },
    height: {
      type: String,
      default: 'h-7'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
