import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    props: true,
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'Login | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'Login user'
        },
        {
          property: 'og:description',
          content: 'Login user'
        }
      ]
    }
  },
  {
    path: '/register',
    name: 'Register',
    props: true,
    component: () => import('../views/Register.vue'),
    meta: {
      title: 'Register | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'user registration'
        },
        {
          property: 'og:description',
          content: 'user registration'
        }
      ]
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    props: true,
    component: () => import('../views/Welcome.vue'),
    meta: {
      title: 'Welcome | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'AlphaPrime welcome page'
        },
        {
          property: 'og:description',
          content: 'AlphaPrime welcome page'
        }
      ]
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    props: true,
    component: () => import('../views/Dashboard.vue'),
    meta: {
      title: 'Dashboard | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'Have a quick overview of your business'
        },
        {
          property: 'og:description',
          content: 'Have a quick overview of your business'
        }
      ]
    }
  },
  {
    path: '/pets',
    name: 'Pets',
    props: true,
    component: () => import('../views/Pets.vue'),
    meta: {
      title: 'Pets | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'Add, update and view pets details'
        },
        {
          property: 'og:description',
          content: 'Add, update and view pets details'
        }
      ]
    }
  },

  {
    path: '/reports',
    name: 'Reports',
    props: true,
    component: () => import('../views/Reports/Reports.vue'),
    meta: {
      title: 'Reports | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'select among eligable client to add report'
        },
        {
          property: 'og:description',
          content: 'select among eligable client to add report'
        }
      ]
    }
  },
  {
    path: '/reports/:client_id',
    name: 'Reports_Client_Profile',
    props: true,
    component: () => import('../views/Reports/ClientProfile.vue'),
    meta: {
      title: 'Reports Client | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'eligable client profile'
        },
        {
          property: 'og:description',
          content: 'eligable client profile'
        }
      ]
    }
  },
  {
    path: '/reports/:pet_id/:report_id/:index',
    name: 'AddReport',
    props: true,
    component: () => import('../views/Reports/AddReports.vue'),
    meta: {
      title: 'Reports | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'Add, update and view pets Reports'
        },
        {
          property: 'og:description',
          content: 'Add, update and view pets Reports'
        }
      ]
    }
  },
  {
    path: '/report_history/:pet_id',
    name: 'ReportHistory',
    props: true,
    component: () => import('../views/ReportHistory.vue'),
    meta: {
      title: ' Report History | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'view pets reports history'
        },
        {
          property: 'og:description',
          content: 'view pets reports history'
        }
      ]
    }
  },
  {
    path: '/clients',
    name: 'Client',
    props: true,
    component: () => import('../views/Client/Client.vue'),
    meta: {
      title: 'Client | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'Add, update and view client details'
        },
        {
          property: 'og:description',
          content: 'Add, update and view client details'
        }
      ]
    }
  },

  {
    path: '/clients/:id',
    name: 'View',
    props: true,
    component: () => import('../views/Client/Profile.vue'),
    meta: {
      title: 'Client | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'view client details'
        },
        {
          property: 'og:description',
          content: 'view client details'
        }
      ]
    }
  },

  {
    path: '/staff',
    name: 'Staff',
    props: true,
    component: () => import('../views/Staff.vue'),
    meta: {
      title: 'Client | AlphaPrime',
      metaTags: [
        {
          name: 'description',
          content: 'Approve and set role for a staff'
        },
        {
          property: 'og:description',
          content: 'Approve and set role for a staff'
        }
      ]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
