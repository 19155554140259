import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
import fb from '@/services/firebase'
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)

Vue.config.productionTip = false

let app

fb.auth.onAuthStateChanged(user => {
  if (!app) {
    if (user) {
      store.dispatch('setUser', user)
    }
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
