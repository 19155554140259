<template>
  <div>
    <svg
      fill="none"
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height, color]"
    >
      <path
        d="m9.4428 0.83496h-5.9459c-1.6693 0-3.0272 1.3581-3.0272 3.0272v5.9459c0 1.6693 1.3579 3.027 3.0272 3.027h5.9459c1.6692 0 3.027-1.3577 3.027-3.027v-5.9459c0-1.6691-1.3577-3.0272-3.027-3.0272zm1.7212 8.973c0 0.94911-0.7722 1.7214-1.7212 1.7214h-5.9459c-0.94897 0-1.7212-0.7723-1.7212-1.7214v-5.9459c0-0.94896 0.77223-1.721 1.7212-1.721h5.9459c0.94895 0 1.7212 0.77205 1.7212 1.721v5.9459zm-1.5042-5.4242c-0.40332 0-0.73149-0.32796-0.73149-0.73126 0-0.40312 0.32817-0.73108 0.73149-0.73108 0.40329 0 0.73129 0.32796 0.73129 0.73108 0 0.4033-0.328 0.73126-0.73129 0.73126zm-6.2806 2.4514c0-1.7041 1.3865-3.0904 3.0904-3.0904 1.7043 0 3.0908 1.3865 3.0908 3.0904 0 1.7041-1.3865 3.0906-3.0908 3.0906-1.7039 0-3.0904-1.3865-3.0904-3.0906zm1.3058 0c0 0.98411 0.80107 1.785 1.7846 1.785 0.98413 0 1.7846-0.80087 1.7846-1.785 0-0.98374-0.80051-1.7842-1.7846-1.7842-0.98356 0-1.7846 0.8005-1.7846 1.7842z"
        clip-rule="evenodd"
        fill="#1B2CC1"
        fill-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-7'
    },
    height: {
      type: String,
      default: 'h-7'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
