<template>
  <div>
    <svg
      width="1253"
      height="701"
      viewBox="0 0 1253 701"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M732.237 -70.4228C695.588 -203.928 528.205 -130.631 337.11 -70.4228C329.256 -59.9518 277.425 -45.2925 132.925 -70.4228C-47.6995 -101.836 -16.2862 68.3178 67.4818 94.4953C151.25 120.673 140.778 167.792 132.925 356.27C125.072 544.748 164.338 570.926 255.96 631.134C347.581 691.342 470.615 570.926 577.943 570.926C685.27 570.926 808.305 686.106 884.219 696.577C960.134 707.048 1214.06 701.813 1248.09 591.868C1282.12 481.922 1090.25 400.772 1090.25 343.181C1090.25 285.591 1164.32 311.768 1180.02 264.649C1195.73 217.529 1247.59 131.144 1135.03 94.4953C1022.46 57.8468 978.305 68.3178 889.302 68.3178C800.298 68.3178 768.885 63.0824 732.237 -70.4228Z"
        stroke="#E1E2E4"
      />
      <path
        d="M372.297 -130.222C338.207 -196.063 318.961 -151.829 225.584 -98.9731C222.638 -92.6123 197.125 -79.1512 118.634 -76.1931C20.5193 -72.4955 55.9565 13.1248 102.754 17.4908C149.551 21.8567 149.324 47.7058 166.238 147.305C183.152 246.904 206.64 256.235 261.347 277.55C316.055 298.865 367.065 222.065 423.282 210.091C479.499 198.117 556.794 244.722 597.726 241.737C638.657 238.752 771.075 207.681 776.634 146.296C782.193 84.9105 672.642 63.8102 666.217 33.6448C659.792 3.47938 701.508 8.92771 704.478 -17.5054C707.448 -43.9385 671.758 -83.6376 608.71 -90.2756C545.662 -96.9136 516.664 -85.0039 470.045 -75.0742C423.426 -65.1445 406.388 -64.3822 372.297 -130.222Z"
        stroke="#E1E2E4"
      />
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
