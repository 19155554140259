<template>
  <div>
    <svg
      fill="none"
      viewBox="0 0 15 13"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current inline-block"
      :class="[width, height]"
    >
      <path
        d="m12.996 6.677-5.7214 5.7214-5.7215-5.7214c-1.4363-1.4363-1.4363-3.765 0-5.2013 1.4363-1.4363 3.765-1.4363 5.2013 0l0.52026 0.52025 0.52001-0.52025c1.4363-1.4363 3.765-1.4363 5.2013 0 1.4363 1.4363 1.4363 3.765 0 5.2013z"
        fill="#E1E2E4"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-5'
    },
    height: {
      type: String,
      default: 'h-5'
    }
  }
}
</script>

<style></style>
