<template>
  <div>
    <svg
      class="fill-current inline-block"
      :class="[width, height]"
      viewBox="0 0 33 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.722352 1.04425C0.300705 1.46544 0.160156 2.16741 0.160156 3.00978V34.0372C0.160156 34.8795 0.441254 35.5815 0.862901 36.0027L1.00345 36.1431L18.4315 18.7341V18.4533L0.722352 1.04425Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M24.0535 24.6307L18.291 18.8745V18.4533L24.0535 12.6971L24.1941 12.8375L31.081 16.7685C33.0487 17.8917 33.0487 19.7169 31.081 20.84L24.0535 24.6307Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M24.1938 24.4902L18.2908 18.5936L0.722168 36.1431C1.42491 36.845 2.40876 36.845 3.6737 36.2834L24.1938 24.4902Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M24.1938 12.6971L3.6737 1.04428C2.40876 0.342302 1.42491 0.482697 0.722168 1.18467L18.2908 18.5937L24.1938 12.6971Z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity="0.2"
        d="M24.0533 24.3499L3.6737 35.8623C2.54931 36.5642 1.56546 36.4239 0.862717 35.8623L0.722168 36.0027L0.862717 36.1431C1.56546 36.7046 2.54931 36.845 3.6737 36.1431L24.0533 24.3499Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M0.722351 35.8623C0.300705 35.4411 0.160156 34.7391 0.160156 33.8967V34.0371C0.160156 34.8795 0.441254 35.5815 0.862901 36.0027V35.8623H0.722351ZM31.0809 20.4188L24.0535 24.3498L24.194 24.4902L31.0809 20.5592C32.0648 19.9976 32.4864 19.2956 32.4864 18.5936C32.4864 19.2956 31.9242 19.8572 31.0809 20.4188Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M3.67437 1.18467L31.0814 16.7686C31.9247 17.3301 32.4869 17.8917 32.4869 18.5937C32.4869 17.8917 32.0653 17.1897 31.0814 16.6282L3.67437 1.04428C1.70668 -0.0788847 0.160645 0.763487 0.160645 3.00981V3.15021C0.160645 1.04428 1.70668 0.0615105 3.67437 1.18467Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="16.7449"
          y1="2.74303"
          x2="-6.81761"
          y2="26.3299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00A0FF" />
          <stop offset="0.007" stop-color="#00A1FF" />
          <stop offset="0.26" stop-color="#00BEFF" />
          <stop offset="0.512" stop-color="#00D2FF" />
          <stop offset="0.76" stop-color="#00DFFF" />
          <stop offset="1" stop-color="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="33.6587"
          y1="18.5951"
          x2="-0.350007"
          y2="18.5951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE000" />
          <stop offset="0.409" stop-color="#FFBD00" />
          <stop offset="0.775" stop-color="#FFA500" />
          <stop offset="1" stop-color="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="20.9992"
          y1="21.8171"
          x2="-10.952"
          y2="53.8032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF3A44" />
          <stop offset="1" stop-color="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="-3.63907"
          y1="-9.23828"
          x2="10.6293"
          y2="5.0457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#32A071" />
          <stop offset="0.069" stop-color="#2DA771" />
          <stop offset="0.476" stop-color="#15CF74" />
          <stop offset="0.801" stop-color="#06E775" />
          <stop offset="1" stop-color="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-7'
    },
    height: {
      type: String,
      default: 'h-7'
    }
  }
}
</script>

<style></style>
