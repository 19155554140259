<template>
  <div>
    <svg
      width="1440"
      height="1096"
      viewBox="0 0 1440 1096"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M673.146 97.7011C720.577 -75.0853 937.209 19.7778 1184.53 97.7011C1194.7 111.253 1261.78 130.226 1448.79 97.7011C1682.56 57.0455 1641.91 277.263 1533.49 311.143C1425.08 345.023 1438.63 406.006 1448.79 649.94C1458.96 893.873 1408.14 927.753 1289.56 1005.68C1170.98 1083.6 1011.74 927.753 872.838 927.753C733.931 927.753 574.697 1076.82 476.446 1090.38C378.195 1103.93 49.5618 1097.15 5.51819 954.857C-38.5253 812.562 209.792 707.535 209.792 633C209.792 558.465 113.933 592.344 93.6053 531.361C73.2775 470.377 6.15991 358.575 151.843 311.143C297.525 263.711 354.677 277.263 469.868 277.263C585.059 277.263 625.714 270.487 673.146 97.7011Z"
        stroke="#E1E2E4"
      />
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
