<template>
  <div id="app">
    <router-view />
    <div
      class="absolute bottom-6 left-6 xl:left-96"
      v-if="new_app_version"
      @click="updateVersion"
    >
      <div class="cursor-pointer">
        <div
          class="
            bg-primary
            animate-bounce
            shadow-md
            hover:shadow-xl
            px-4
            py-4
            text-white text-sm
            rounded-md
          "
        >
          New Version Available! Click to update
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    new_app_version() {
      return this.$store.state.new_app_version
    }
  },
  methods: {
    updateVersion() {
      this.$store.dispatch('addAppVersionToLocalStorage')
    }
  },
  mounted() {
    this.$store.dispatch('appVersion')
  }
}
</script>

<style></style>
