import router from '../../../router'
import Swal from 'sweetalert2'
import fb from '../../../services/firebase'
import store from '../..'

export const namespaced = true

export const state = {
  report_history: [],
  loading: false,
  report: {}
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_REPORT_HISTORY_LOADING(state, payload) {
    state.report_history = payload
  },
  SET_REPORT(state, payload) {
    state.report = payload
  }
}

export const actions = {
  getPetReportHistory({ commit }, payload) {
    commit('SET_LOADING', true)

    var id = payload

    fb.db
      .collection('reports')
      .where('pet_id', '==', id)
      .where('finishedAt', '==', true)
      .onSnapshot(querySnapshot => {
        var container = []
        querySnapshot.forEach(doc => {
          var pet_report_history = doc.data()
          pet_report_history.id = doc.id

          container.push(pet_report_history)
        })

        commit('SET_REPORT_HISTORY_LOADING', container)
        commit('SET_LOADING', false)
      })
  },

  editReportHistory({ commit }, payload) {
    commit('SET_LOADING', true)

    console.log(payload)
    fb.db
      .collection('reports')
      .where('finishedAt', '==', false)
      .get()
      .then(doc => {
        if (doc.empty == false) {
          var id = doc.docs[0].id
          console.log(id)

          Swal.fire({
            icon: 'warning',
            title: 'you have a report still open',
            text: 'what would you like to do?',
            showDenyButton: true,
            confirmButtonText: 'close previous report and edit this report',
            denyButtonText: `go to prevous report`
          }).then(result => {
            if (result.isConfirmed) {
              store.dispatch('reports_history/updateExistingReport', id)
              store.dispatch('reports_history/updateReportFromHistory', payload)
              router.push({
                name: 'AddReport',
                params: {
                  pet_id: payload.pet_id,
                  report_id: payload.report_id,
                  index: payload.index
                }
              })
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'saved successfully',
                showConfirmButton: false,
                timer: 800
              })
            } else if (result.isDenied) {
              router.push({
                name: 'AddReport',
                params: {
                  pet_id: payload.pet_id,
                  report_id: id,
                  index: 1
                }
              })
            }
          })
        } else if (doc.empty == true) {
          store.dispatch('reports_history/updateReportFromHistory', payload)
          store.dispatch('reports_history/updatePet', payload)
          router.push({
            name: 'AddReport',
            params: {
              pet_id: payload.pet_id,
              report_id: payload.report_id,
              index: payload.index
            }
          })
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'saved successfully',
            showConfirmButton: false,
            timer: 800
          })
        }
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')

        commit('SET_LOADING', false)
      })
  },

  updateExistingReport({ commit }, payload) {
    commit('SET_LOADING', true)

    fb.db
      .collection('reports')
      .doc(payload)
      .update({ finishedAt: true, updatedAt: Date.now() })
      .then(() => {
        commit('SET_LOADING', false)
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')

        commit('SET_LOADING', false)
      })
  },

  updateReportFromHistory({ commit }, payload) {
    commit('SET_LOADING', true)

    var id = payload.report_id

    fb.db
      .collection('reports')
      .doc(id)
      .update({ finishedAt: false, updatedAt: Date.now() })
      .then(() => {
        commit('SET_LOADING', false)
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')

        commit('SET_LOADING', false)
      })
  },

  updatePet({ commit }, payload) {
    commit('SET_LOADING', true)

    var pet_id = payload.pet_id

    fb.db
      .collection('pets')
      .doc(pet_id)
      .update({ isActive: true, updatedAt: Date.now() })
      .then(() => {
        commit('SET_LOADING', false)
      })
      .catch(error => {
        var errorCode = error.code
        var errorMsg = error.message
        Swal.fire(errorCode, errorMsg, 'error')

        commit('SET_LOADING', false)
      })
  }
}

export const getters = {}
