<template>
  <div>
    <svg
      id="wave"
      style="transform: rotate(0deg); transition: 0.3s"
      viewBox="0 0 1440 170"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-3" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(9, 21, 64, 1)" offset="0%"></stop>
          <stop stop-color="rgba(9, 21, 64, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style="transform: translate(0, 0px); opacity: 1"
        fill="url(#sw-gradient-3)"
        d="M0,68L20,62.3C40,57,80,45,120,53.8C160,62,200,91,240,90.7C280,91,320,62,360,65.2C400,68,440,102,480,121.8C520,142,560,147,600,141.7C640,136,680,119,720,102C760,85,800,68,840,53.8C880,40,920,28,960,34C1000,40,1040,62,1080,85C1120,108,1160,130,1200,121.8C1240,113,1280,74,1320,51C1360,28,1400,23,1440,34C1480,45,1520,74,1560,76.5C1600,79,1640,57,1680,39.7C1720,23,1760,11,1800,19.8C1840,28,1880,57,1920,79.3C1960,102,2000,119,2040,130.3C2080,142,2120,147,2160,141.7C2200,136,2240,119,2280,93.5C2320,68,2360,34,2400,34C2440,34,2480,68,2520,85C2560,102,2600,102,2640,87.8C2680,74,2720,45,2760,45.3C2800,45,2840,74,2860,87.8L2880,102L2880,170L2860,170C2840,170,2800,170,2760,170C2720,170,2680,170,2640,170C2600,170,2560,170,2520,170C2480,170,2440,170,2400,170C2360,170,2320,170,2280,170C2240,170,2200,170,2160,170C2120,170,2080,170,2040,170C2000,170,1960,170,1920,170C1880,170,1840,170,1800,170C1760,170,1720,170,1680,170C1640,170,1600,170,1560,170C1520,170,1480,170,1440,170C1400,170,1360,170,1320,170C1280,170,1240,170,1200,170C1160,170,1120,170,1080,170C1040,170,1000,170,960,170C920,170,880,170,840,170C800,170,760,170,720,170C680,170,640,170,600,170C560,170,520,170,480,170C440,170,400,170,360,170C320,170,280,170,240,170C200,170,160,170,120,170C80,170,40,170,20,170L0,170Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
