import fb from '../../services/firebase'
// import Swal from 'sweetalert2'

export const namespaced = true

export const state = {
  users: [],
  user: {}
}

export const mutations = {
  GET_PENDING(state, value) {
    state.users = value
  },
  GET_APPROVED(state, value) {
    state.users = value
  },
  SET_STATUS(state, payload) {
    state.user = payload
  }
}

export const actions = {
  getPending({ commit }) {
    commit('GET_PENDING', [])
    fb.db
      .collection('users')
      .where('isApproved', '==', false)
      .onSnapshot(querySnapshot => {
        var container = []
        querySnapshot.forEach(doc => {
          var getPending = doc.data()
          getPending.id = doc.id
          container.push(getPending)
        })
        commit('GET_PENDING', container)
      })
  },

  getApproved({ commit }) {
    commit('GET_APPROVED', [])

    fb.db
      .collection('users')
      .where('isApproved', '==', true)
      .onSnapshot(querySnapshot => {
        var container = []
        querySnapshot.forEach(doc => {
          var getApproved = doc.data()
          getApproved.id = doc.id
          container.push(getApproved)
        })
        commit('GET_APPROVED', container)
      })
  },

  updateStatus({ commit }, payload) {
    commit('SET_STATUS', {})
    return fb.db
      .collection('users')
      .doc(payload.id)
      .update({
        isApproved: true,
        role: payload.role
      })
      .then(() => {
        commit('SET_STATUS', payload)
      })
  },

  disableStatus({ commit }, payload) {
    commit('SET_STATUS', {})
    return fb.db
      .collection('users')
      .doc(payload)
      .update({
        isApproved: false
      })
      .then(() => {
        commit('SET_STATUS', payload)
      })
  }
}

export const getters = {}
