<template>
  <div>
    <svg
      id="wave"
      style="transform: rotate(0deg); transition: 0.3s"
      viewBox="0 0 1440 190"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(208, 213, 239, 1)" offset="0%"></stop>
          <stop stop-color="rgba(208, 213, 239, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style="transform: translate(0, 0px); opacity: 1"
        fill="url(#sw-gradient-0)"
        d="M0,57L24,63.3C48,70,96,82,144,82.3C192,82,240,70,288,69.7C336,70,384,82,432,79.2C480,76,528,57,576,50.7C624,44,672,51,720,72.8C768,95,816,133,864,142.5C912,152,960,133,1008,114C1056,95,1104,76,1152,79.2C1200,82,1248,108,1296,101.3C1344,95,1392,57,1440,50.7C1488,44,1536,70,1584,79.2C1632,89,1680,82,1728,95C1776,108,1824,139,1872,155.2C1920,171,1968,171,2016,167.8C2064,165,2112,158,2160,158.3C2208,158,2256,165,2304,152C2352,139,2400,108,2448,85.5C2496,63,2544,51,2592,50.7C2640,51,2688,63,2736,57C2784,51,2832,25,2880,38C2928,51,2976,101,3024,110.8C3072,120,3120,89,3168,66.5C3216,44,3264,32,3312,28.5C3360,25,3408,32,3432,34.8L3456,38L3456,190L3432,190C3408,190,3360,190,3312,190C3264,190,3216,190,3168,190C3120,190,3072,190,3024,190C2976,190,2928,190,2880,190C2832,190,2784,190,2736,190C2688,190,2640,190,2592,190C2544,190,2496,190,2448,190C2400,190,2352,190,2304,190C2256,190,2208,190,2160,190C2112,190,2064,190,2016,190C1968,190,1920,190,1872,190C1824,190,1776,190,1728,190C1680,190,1632,190,1584,190C1536,190,1488,190,1440,190C1392,190,1344,190,1296,190C1248,190,1200,190,1152,190C1104,190,1056,190,1008,190C960,190,912,190,864,190C816,190,768,190,720,190C672,190,624,190,576,190C528,190,480,190,432,190C384,190,336,190,288,190C240,190,192,190,144,190C96,190,48,190,24,190L0,190Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
