<template>
  <main class="bg-background_color w-full h-full xl:min-h-screen bg-opacity-50">
    <section class="sm:relative">
      <div class="hidden xl:block absolute -top-10 xl:-left-20 xxl:left-0">
        <laptop-hero class="bounce_animation"></laptop-hero>
      </div>
      <div class="hidden xxl:block absolute top-0 right-0 left-18%">
        <background-lines></background-lines>
      </div>
      <div class="hidden xl:block absolute lg:top-10 top-0 right-0">
        <phone-hero class="bounce_animation"></phone-hero>
      </div>
      <section>
        <div
          class="
            xl:w-1/2
            w-full
            h-full
            flex flex-col
            justify-center
            text-center
            sm:pt-40
            pt-36
            mx-auto
            sm:mb-40
            mb-28
          "
        >
          <div class="z-30 w-5/6 mx-auto">
            <div class="text-primary text-5xl sm:text-7xl font-semibold">
              <h1 class="leading-tight lead-hero-text">
                Upgrade your veterinary practice
              </h1>
            </div>
            <div class="mt-9">
              <p class="sm:text-2xl text-lg sub-hero-text">
                Alpha Prime is the new way to organize your animal health
                records and clinical cases.
              </p>
            </div>
            <div
              class="
                flex flex-col
                sm:flex-row
                items-center
                justify-center
                mt-14
                sm:space-x-4
                space-y-5
                sm:space-y-0
              "
            >
              <router-link
                v-if="user_logged == false"
                to="/register"
                class="
                  bg-primary
                  block
                  rounded-full
                  shadow-md
                  py-4
                  sm:px-10
                  px-16
                  hover:shadow-xl
                  text-white text-center
                "
              >
                Sign Up
              </router-link>
              <router-link
                v-if="user_logged == true"
                to="/dashboard"
                class="
                  bg-primary
                  block
                  rounded-full
                  shadow-md
                  py-4
                  sm:px-10
                  px-16
                  hover:shadow-xl
                  text-white text-center
                "
              >
                Dashboard
              </router-link>
              <router-link
                to="/"
                class="
                  bg-black
                  flex
                  items-center
                  rounded-full
                  shadow-md
                  py-2
                  px-8
                  hover:shadow-xl
                  text-white text-center
                "
              >
                <google-play-icon></google-play-icon>
                <div class="text-left leading-5 space-x-2 sub-hero-text">
                  <small class="pl-2">Get It On</small>
                  <p>Google Play</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center space-x-4">
          <p class="text-lg">Proudly supported by</p>
          <div>
            <img
              src="/img/1280px-Google_org_logo.png"
              alt=""
              class="w-32 sm:w-full"
            />
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import BackgroundLines from '../Icons/BackgroundLines.vue'
import GooglePlayIcon from '../Icons/GooglePlayIcon.vue'
import laptopHero from '../Icons/laptopHero.vue'
import PhoneHero from '../Icons/PhoneHero.vue'
import fb from '../../services/firebase'
export default {
  components: {
    laptopHero,
    PhoneHero,
    BackgroundLines,
    GooglePlayIcon
  },
  data() {
    return {
      user_logged: false,
      user_uid: null
    }
  },

  mounted() {
    fb.auth.onAuthStateChanged(user => {
      if (user) {
        this.user_logged = true
        this.user_uid = user.uid
      } else {
        this.user_logged = false
      }
    })
  }
}
</script>

<style scoped>
.lead-hero-text,
.sub-hero-text {
  font-family: 'Comfortaa', cursive;
}

.bounce_animation {
  animation: bounce 7s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-2%);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>
